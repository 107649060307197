<template>
  <div class="d-flex flex-column text-center">
    <div style="zindex: 10; min-height: 350px" class="mt-2">
      <div class="mt-6">
        <v-form v-model="validGrossWeight">
          <div class="mb-5">
            <div class="text-overline font-weight-light">
              {{
                currentEntry.id
                  ? $vuetify.lang.t("$vuetify.worksheet.editEntry")
                  : $vuetify.lang.t("$vuetify.base.entry")
              }}
              {{ currentEntryIndex }}
            </div>
          </div>

          <div class="d-flex justify-center">
            <div v-if="worksheetTypeFields.hasInventorySelection">
              <v-btn
                v-if="selectedInventory"
                @click="showInventoryDetails = true"
                class="mr-5 my-2"
                :title="`Selected inventory: ${selectedInventoryLabel}`"
                icon
              >
                <v-icon>mdi-information-outline</v-icon>
              </v-btn>
              <div v-else style="min-width: 36px"></div>
            </div>

            <v-text-field
              ref="inputField"
              class="shrink"
              v-model.number="currentEntry.grossWeight"
              style="font-size: 26px"
              outlined
              :label="$vuetify.lang.t('$vuetify.base.grossWeight')"
              type="number"
              inputmode="decimal"
              :min="worksheet.deductibleWeight"
              :disabled="loading || submitted"
              :readonly="selectedInventoryUnit"
              :rules="[minWeightRule]"
              :hint="selectedInventoryHint"
              :validate-on-blur="false"
              autofocus
            >
              <template v-slot:append>
                <span v-if="productInfo">
                  {{
                    productInfo.measureType == "Unit"
                      ? `${productInfo.measureWeight}/${productInfo.measureUnit}`
                      : productInfo.measureWeight
                  }}
                </span>
              </template>
            </v-text-field>

            <div
              class="d-flex"
              v-if="worksheetTypeFields.hasInventorySelection && worksheet.inventorySelectionOnly"
            >
              <v-btn
                icon
                class="ml-5 my-2"
                v-if="
                  worksheet.productId ||
                  (worksheet.products && worksheet.products.length > 0)
                "
                @click="openInventorySelection()"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
              <div class="mt-2 ml-2">
                <qr-scanner
                  :manualProcess="true"
                  @captured="processScannedInventory"
                />
              </div>
            </div>
          </div>

          <!-- <div class="text-caption font-weight-light mb-5" v-if="selectedInventory">Selected inventory: {{selectedInventoryHint}}</div> -->
        </v-form>

        <div
          v-if="hasDeductibleWeight"
          class="text-subtitle-2 mt-4 d-flex justify-center"
        >
          <div class="d-flex flex-column flex-sm-row justify-center">
            <span class="mx-2">{{
              $vuetify.lang.t("$vuetify.base.deductibleWeight")
            }}</span>
            <span
              >{{ worksheet.deductibleWeight | formatNumber }}
              {{ productInfo ? productInfo.measureWeight : "" }}</span
            >
          </div>
          <v-divider vertical class="mx-4" />
          <div class="d-flex flex-column flex-sm-row justify-center">
            <span class="mx-2">{{
              $vuetify.lang.t("$vuetify.base.netWeight")
            }}</span>
            <span
              >{{ netWeight | formatNumber }}
              {{ productInfo ? productInfo.measureWeight : "" }}</span
            >
          </div>
        </div>
      </div>

      <div
        style="min-height: 130px"
        :class="`d-flex ${entryIconsLayout} justify-center`"
      >
        <div
          v-if="worksheetTypeFields.hasEntryNotes"
          class="d-flex justify-center mt-4 mx-2"
        >
          <v-btn
            v-show="!showEntryNotes"
            icon
            @click="showEntryNotes = !showEntryNotes"
            color="primary"
            ><v-icon x-large>mdi-note-edit</v-icon></v-btn
          >
          <v-expand-x-transition>
            <div v-show="showEntryNotes">
              <v-textarea
                append-icon="mdi-close"
                style="min-width: 300px"
                @click:append="showEntryNotes = false"
                :label="$vuetify.lang.t('$vuetify.base.notes')"
                v-model="currentEntry.notes"
                :disabled="loading || submitted"
                hide-details
                :rows="3"
                outlined
              />
            </div>
          </v-expand-x-transition>
          <div
            v-if="currentEntry.notes && !showEntryNotes"
            class="font-weight-light text-subtitle-2 mx-2"
            style="white-space: pre"
          >
            {{ currentEntry.notes }}
          </div>
        </div>

        <div class="d-flex justify-center">
          <image-upload
            class="mt-4 mx-2"
            v-if="worksheetTypeFields.hasEntryPhoto"
            v-model="currentEntry.files"
            :key="`${currentEntry.id}-${addTime}`"
            :loading="loading"
            :disabled="loading || submitted"
          />
        </div>
      </div>

      <div class="mt-5">
        <v-btn
          :loading="loading"
          :disabled="submitted || !validGrossWeight"
          @click="saveWorksheetEntry(worksheet, currentEntry)"
          color="primary"
        >
          {{
            currentEntry.id
              ? $vuetify.lang.t("$vuetify.base.update")
              : $vuetify.lang.t("$vuetify.base.add")
          }}
        </v-btn>
      </div>
    </div>

    <v-dialog
      style="z-index: 1000"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="inventoryLookupDialog"
    >
      <v-card>
        <v-card-title class="d-flex justify-end">
          <v-btn icon @click="inventoryLookupDialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text class="px-4 px-sm-6 px-md-8">
          <inventory-selection-by-product
            @change="inventoryChanged"
            :key="timestamp"
            :productId="worksheet.productId"
            :products="worksheet.products"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet scrollable v-model="showInventoryDetails">
      <v-card>
        <v-card-title class="justify-center">
          <v-btn class="mt-6" text @click="showInventoryDetails = false">
            {{ this.$vuetify.lang.t("$vuetify.close") }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div v-if="selectedInventory">
            <v-divider />
            <div v-if="selectedInventoryUnit">
              <div class="d-flex justify-space-around text-subtitle-2">
                <span
                  >{{
                    this.$vuetify.lang.t("$vuetify.worksheet.selectedUnit")
                  }}:</span
                >
                <div class="d-flex">
                  <span>{{ selectedInventoryUnit.code }}</span>
                  <v-divider class="mx-2" vertical />
                  <span
                    >{{ selectedInventoryUnit.amountPerUnit | formatNumber }}
                    {{ selectedInventoryUnit.unitMeasure }}</span
                  >
                  <v-divider class="mx-2" vertical />
                  <span
                    >{{ selectedInventoryUnit.unitShare }}
                    {{ productInfo ? productInfo.measureUnit : "" }}</span
                  >
                </div>
              </div>
            </div>

            <inventory-details
              :key="selectedInventory ? selectedInventory.id : 0"
              v-model="selectedInventory"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const QrScanner = () => import("@/components/common/QrScanner.vue");
const InventorySelectionByProduct = () =>
  import("@/components/inventory/InventorySelectionByProduct.vue");
const InventoryDetails = () =>
  import("@/components/inventory/InventoryDetails.vue");
const ImageUpload = () => import("@/components/common/ImageUpload.vue");
import { findInventory } from "@/services/processWorksheet.js";
import { tagReservedInventories } from "@/services/inventoryReservation.js";
import { sumWorksheetWeight } from "@/services/worksheetServices";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    currentEntry: {
      type: Object,
      required: true,
    },
    worksheetTypeFields: {
      type: Object,
      required: true,
    },
    productInfo: {
      type: Object,
      required: false,
    },
  },
  data: () => {
    return {
      loading: false,
      inventoryLookupDialog: false,
      validGrossWeight: false,
      showEntryNotes: false,
      addTime: Date.now(),
      imageData: null,
      sum: { grossWeight: null, netWeight: 0 },
      selectedInventory: null,
      selectedInventoryUnit: null,
      timestamp: Date.now(),
      showInventoryDetails: false,
    };
  },
  watch: {
    currentEntry: function (newVal, oldVal) {
      if (newVal && oldVal && newVal.inventoryId != oldVal.inventoryId) {
        this.selectedInventory = null;
        this.selectedInventoryUnit = null;
        console.log("selected inventory changed...");
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    weightUnit() {
      if (this.productInfo) {
        return this.productInfo.measureType == "Unit"
          ? this.productInfo.measureUnit
          : this.productInfo.measureWeight;
      } else {
        return null;
      }
    },
    entryIconsLayout() {
      const hasFiles =
        this.currentEntry.files && this.currentEntry.files.length > 0;
      const hasNotes = this.showEntryNotes || this.currentEntry.notes;
      const useColumn = hasFiles || hasNotes;
      return useColumn ? "flex-column" : "flex-row";
    },
    hasDeductibleWeight() {
      return (
        this.worksheetTypeFields.hasDeductibleWeight &&
        this.worksheet.deductibleWeight &&
        this.worksheet.deductibleWeight > 0
      );
    },
    netWeight() {
      return (
        (this.currentEntry.grossWeight ? this.currentEntry.grossWeight : 0) -
        this.worksheet.deductibleWeight
      );
    },
    hasSupplierOrCustomerOrSettings() {
      return (
        this.worksheetTypeFields &&
        (this.worksheetTypeFields.hasWeightSettings ||
          this.worksheetTypeFields.hasSupplier ||
          this.worksheetTypeFields.hasCustomer)
      );
    },
    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
    currentEntryIndex() {
      if (this.worksheet.entries && this.worksheet.entries.length > 0) {
        if (this.currentEntry.id) {
          const index = this.worksheet.entries.findIndex(
            (e) => e.id == this.currentEntry.id
          );
          return index + 1;
        } else {
          return this.worksheet.entries.length + 1;
        }
      } else {
        return 1;
      }
    },
    submitted() {
      if (
        this.worksheet &&
        ["SUBMITTED", "USED"].includes(this.worksheet.status)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
    selectedInventoryHint() {
      if (!this.worksheetTypeFields.hasInventorySelection) {
        return null;
      }

      if (this.selectedInventory) {
        const inventoryDate = this.$options.filters.formatDateYear(
          this.selectedInventory.stockTime
        );
        if (this.selectedInventoryUnit) {
          const productMeasureUnit = this.productInfo
            ? this.productInfo.measureUnit
            : "";
          const productMeasureWeight = this.productInfo
            ? this.productInfo.measureWeight
            : "";
          return `${inventoryDate} - ${
            this.currentEntry.unit
          } ${productMeasureUnit} @ ${this.$options.filters.formatNumber(
            this.currentEntry.amountPerUnit
          )}${productMeasureWeight}/${productMeasureUnit}`;
        } else {
          return `${inventoryDate} - ${this.$options.filters.formatNumber(
            this.selectedInventory.quantityAvailable -
              this.selectedInventory.reservedQuantity
          )} available`;
        }
      } else {
        return this.$vuetify.lang.t("$vuetify.worksheet.noInventorySelected");
      }
    },
  },
  components: {
    ImageUpload,
    InventorySelectionByProduct,
    InventoryDetails,
    QrScanner,
  },
  async created() {
    this.sumWeight();

    if (this.currentEntry && this.currentEntry.inventoryId) {
      const inventory = await this.fetchInventory(
        this.currentEntry.inventoryId
      );
      this.selectedInventory = inventory;
      this.selectedInventoryUnit = inventory.unitDetails.find(
        (d) => d.id == this.currentEntry.inventoryUnitId
      );
    }
  },
  methods: {
    ...mapActions("messages", [
      "addMessage",
      "addErrorMessage",
      "addSuccessMessage",
    ]),
    async saveWorksheetEntry(worksheet, entry) {
      this.loading = true;

      if (!this.validGrossWeight) {
        this.loading = false;
        return;
      }

      if (worksheet.requirePhoto && (!entry.files || entry.files.length < 1)) {
        this.addErrorMessage(
          this.$vuetify.lang.t("$vuetify.worksheet.photoRequired")
        );
        this.loading = false;
        return;
      }

      if (worksheet.inventorySelectionOnly && entry.inventoryId == null) {
        this.addErrorMessage(
          this.$vuetify.lang.t("$vuetify.worksheet.inventoryRequired")
        );
        this.loading = false;
        return;
      }

      const unit = entry.unit;
      const amountPerUnit = entry.amountPerUnit;
      const grossWeight = entry.grossWeight ? entry.grossWeight : 0;
      const netWeight =
        grossWeight -
        (worksheet.deductibleWeight ? worksheet.deductibleWeight : 0);

      //selected inventory and not inventory unit and grossWeight is greater than available
      if (
        this.selectedInventory &&
        this.selectedInventory.quantityAvailable &&
        !this.selectedInventoryUnit
      ) {
        const availableQuantity =
          this.selectedInventory.quantityAvailable -
          this.selectedInventory.reservedQuantity;
        if (netWeight > availableQuantity) {
          this.loading = false;
          this.addErrorMessage(
            this.$vuetify.lang.t("$vuetify.worksheet.error.greaterThanAvailable")
          );
          return;
        }
      }

      const data = {
        processWorksheetId: worksheet.id,
        unit,
        amountPerUnit,
        grossWeight,
        netWeight,
        staffId: this.currentUser.uId,
        inventoryId: entry.inventoryId,
        inventoryUnitId: entry.inventoryUnitId,
      };
      if (this.worksheetTypeFields.hasEntryNotes) {
        data.notes = entry.notes;
      }
      if (entry.id) {
        if (entry.files && entry.files.length > 0) {
          const newFiles = entry.files.filter((f) => !f.id);
          if (newFiles && newFiles.length > 0) {
            await this.uploadFiles(entry.id, newFiles);
          }
        }

        await this.$axios
          .put(`/processWorksheetInput/${entry.id}`, data)
          .then((resp) => {
            const updatedEntry = resp.data;
            updatedEntry.createTime = Date.now();
            updatedEntry.updateTime = Date.now();
            const matchIndex = this.worksheet.entries.findIndex(
              (e) => e.id == updatedEntry.id
            );
            this.worksheet.entries[matchIndex] = updatedEntry;

            this.$set(this.worksheet.entries, matchIndex, updatedEntry);

            this.resetEntry();
            this.addSuccessMessage("Entry updated successfully");

          })
          .catch((error) => {
            let msg = error.data ? error.data.message : error;
            if(msg == 'Insufficent inventory available'){
              msg = this.$vuetify.lang.t("$vuetify.worksheet.error.insufficientInventory")
            }
            this.addErrorMessage(msg);
            return null;
          })
          .finally( () => {
            this.loading = false;
          })
      } else {
        await this.$axios
          .post("/processWorksheetInput", data)
          .then(async (resp) => {
            const saved = resp.data;
            saved.createTime = Date.now();
            saved.updateTime = Date.now();
            if (this.worksheet.entries) {
              this.worksheet.entries.push(saved);
            } else {
              this.worksheet.entries = [saved];
            }

            if (entry.files && entry.files.length > 0) {
              await this.uploadFiles(saved.id, entry.files);
            }

            this.resetEntry();
            this.addSuccessMessage("Entry added successfully");
            return saved;
          })
          .catch((error) => {
            let msg = error.data ? error.data.message : error;
            if(msg == 'Insufficent inventory available'){
              msg = this.$vuetify.lang.t("$vuetify.worksheet.error.insufficientInventory")
            }
            this.addErrorMessage(msg);
            return null;
          })
          .finally( () => {
            this.loading = false;
          })
      }

      this.loading = false;
    },
    resetEntry() {
      this.sumWeight();
      this.currentEntry = { grossWeight: null };
      this.selectedInventory = null;
      this.selectedInventoryUnit = null;
      this.$emit("update:currentEntry", this.currentEntry);
      this.focusAndSelectInput();
    },
    async uploadFiles(entryId, files) {
      if (files && files.length > 0) {
        var formData = new FormData();
        for (const f of files) {
          formData.append("files", f, f.name);
        }

        this.addMessage(this.$vuetify.lang.t("$vuetify.worksheet.uploading"));
        return await this.$axios
          .post(`/processWorksheetInput/upload/${entryId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((resp) => {
            const savedFiles = resp.data;
            const entry = this.worksheet.entries.find((e) => e.id == entryId);
            if (!entry.files) {
              entry.files = [];
            }
            savedFiles.forEach((f) => {
              entry.files.push({
                id: f.id,
                name: f.originalFileName,
                size: f.size,
              });
            });

            this.addTime = Date.now();
            this.addSuccessMessage(
              this.$vuetify.lang.t(
                "$vuetify.worksheet.uploadedXFiles",
                files.length
              )
            );
          });
      }
    },

    async sumWeight() {
      this.sum = await sumWorksheetWeight(this.worksheet);
      this.$emit("update:sum", this.sum);
    },
    focusAndSelectInput() {
      let grossWeightInput = this.$refs.inputField.$el.querySelector("input");
      setTimeout(() => {
        grossWeightInput.select();
      }, 200);
    },
    minWeightRule(v) {
      if (v !== 0 && !v) {
        return this.$vuetify.lang.t("$vuetify.validation.fieldRequired");
      } else if (v < 0) {
        return this.$vuetify.lang.t("$vuetify.validation.greaterThan", 0);
      } else if (
        this.worksheet.deductibleWeight &&
        v <= this.worksheet.deductibleWeight
      ) {
        return this.$vuetify.lang.t(
          "$vuetify.validation.smallerThanDeductible"
        );
      } else {
        return true;
      }
    },
    openInventorySelection() {
      this.inventoryLookupDialog = true;
      this.timestamp = Date.now();
    },
    inventoryChanged(inventory, inventoryUnit) {
      this.selectedInventory = inventory;
      this.currentEntry.inventoryId = inventory.id;
      this.selectedInventoryUnit = inventoryUnit;
      if (inventoryUnit) {
        const availableUnits =
          inventoryUnit.unitShare -
          (inventoryUnit.usedUnitShare ? inventoryUnit.usedUnitShare : 0);
        this.currentEntry.unit = availableUnits;
        this.currentEntry.amountPerUnit = inventoryUnit.amountPerUnit;
        this.currentEntry.grossWeight =
          inventoryUnit.amountPerUnit * availableUnits;
        this.currentEntry.inventoryUnitId = inventoryUnit.id;
      } else {
        this.currentEntry.grossWeight =
          inventory.quantityAvailable - inventory.reservedQuantity;
      }
      this.inventoryLookupDialog = false;
    },
    processScannedInventory(qrCodeMessage) {
      if (
        typeof qrCodeMessage == "object" &&
        (qrCodeMessage.businessType == "WorksheetEntry" ||
          qrCodeMessage.businessType == "Inventory")
      ) {
        const worksheetId = qrCodeMessage.worksheetId;
        const worksheetEntryId = qrCodeMessage.worksheetEntryId;
        findInventory(worksheetId, worksheetEntryId).then(async (resp) => {
          if (resp && resp.data) {
            const inventory = resp.data;
            if (!this.allowedProduct(inventory.productId)) {
              if (this.worksheet.productName) {
                this.addErrorMessage(
                  "Please scan a " + this.worksheet.productName + " QR Code"
                );
              } else if (this.worksheet.products) {
                const productNames = this.worksheet.products
                  .map((p) => p.name)
                  .join(", ");
                this.addErrorMessage(
                  "Please scan a " + productNames + " QR Code"
                );
              } else {
                this.addErrorMessage("Invalid QR Code");
              }
            } else {
              await tagReservedInventories(inventory);
              const inventoryUnit = inventory.selectedUnitDetail;
              this.inventoryChanged(inventory, inventoryUnit);
              this.showInventoryDetails = true;
            }
          } else {
            this.addErrorMessage("No inventory information available");
          }
        });
      } else {
        this.addErrorMessage("Unrecognizable QR Code detected");
      }
    },
    allowedProduct(productId) {
      let allowed = false;
      if (productId == this.worksheet.productId) {
        allowed = true;
      } else if (
        this.worksheet.products &&
        this.worksheet.products.length > 0
      ) {
        allowed = this.worksheet.products.map((p) => p.id).includes(productId);
      }

      return allowed;
    },
    async fetchInventory(inventoryId) {
      return await this.$axios
        .get("/inventory/" + inventoryId)
        .then((response) => {
          this.inventory = response.data;
          return this.inventory;
        })
        .catch(() => {
          this.inventory = null;
          this.addErrorMessage("No inventory found with ID " + inventoryId);
          return null;
        });
    },
  },
};
</script>

<style></style>
